<template>
  <Layout>
    <PageHeader :title="title" moda_title="Manual payment general" ref="pageForm" :items="items">
    </PageHeader>
    <sister-round ref="sisterRound" :round="round" :rounds="rounds"
      :roundIdentifierNumber="roundIdentifierNumber"></sister-round>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado podrás realizar pagos a todos los jugadores
                  en una ronda en la cual haya ocurrido un error.
                </p>
                <ol>
                  <li>
                    Buscar por id de ronda (Este ID lo ve el jugador cuando está
                    apostando, y en el reporte de rondas lo puedes identificar
                    también.)
                  </li>
                  <li>
                    Podrás visualizar todas las rondas hermanas por un ID de
                    ronda.
                  </li>
                  <li>Efectua el pago luego de haber verificado el error.</li>
                </ol>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <b-col sm="12" md="3">
                <div class="mb-3">
                  <h5>{{ $t('generalPayment.search') }}</h5>
                  <vs-input v-model="typeform.identifierNumber" :placeholder="$t('transactions.roundID')" type="number"
                    border class="shadow-lg" :class="{
                      'is-invalid':
                        typesubmit && $v.typeform.identifierNumber.$error,
                    }" />
                  <div v-if="typesubmit && $v.typeform.identifierNumber.$error" class="invalid-feedback">
                    <span v-if="$v.typeform.identifierNumber.required">
                      Este campo es necesario.
                    </span>
                    <span v-if="$v.typeform.identifierNumber.numeric">
                      Este campo debe ser numerico.
                    </span>
                  </div>
                  <vs-button type="submit" @click="findRoundByIdentifierNumber" class="mt-3">
                    {{ $t('filter.search') }}
                  </vs-button>
                </div>
              </b-col>
              <div class="col-sm-12 col-md-12 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                        }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row mt-2" v-if="tableData.length">
              <b-overlay rounded="lg">
                <b-table :items="tableData" :fields="fields" responsive="sm" :filter-included-fields="filterOn"
                  :filter="filter" :sort-by="sortBy" :sort-desc="sortDesc">
                  <template #cell(result)="data">
                    <span :class="`text-light p-1  ${data.item.result === -1
                      ? ''
                      : colorResult(data.item.result)}`">
                      {{ result(data.item.result) }}
                    </span>
                  </template>
                  <template #cell(sisterRound)="data">
                    <div class="d-flex align-items-center justify-content-center">
                      <vs-button @click="lookSisterRound(data.item)">
                        {{ $t('helpers.look') }}
                      </vs-button>
                    </div>
                  </template>
                </b-table>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "../../../components/page-header-simple.vue";
import { breadCrumbData } from "../../../helpers/breadcrumbs/breadCrumbs";
import { numeric, required } from "vuelidate/lib/validators";
import SisterRound from "../reports/rounds/sisterRound.vue";
import Tooltip from "../../../components/tooltip.vue";
import { mapGetters } from "vuex";

export default {
  components: { Layout, PageHeader, SisterRound, Tooltip },
  computed: {
    ...mapGetters({
      result: 'reports/formatRouletteResults',
      colorResult: 'reports/colorResult'
    })
  },
  data() {
    return {
      title: this.$t('generalPayment.title'),
      items: breadCrumbData.generalPayment,
      typeform: {},
      typesubmit: false,
      tableData: [],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterOn: ["code"],
      filter: "",
      sortBy: "createdAt",
      sortDesc: true,
      round: {},
      rounds: [],
      roundIdentifierNumber: 0,
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
      fields: [
        {
          key: "identifierNumber",
          sortable: true,
          label: this.$t('tableReports.id').toUpperCase(),
        },
        {
          key: "code",
          sortable: true,
          label: this.$t('transactions.roundID').toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "roulette.name",
          sortable: true,
          label: this.$t('roulettes.name').toUpperCase(),
        },
        {
          key: "open",
          sortable: true,
          label: this.$t('tableReports.status').toUpperCase(),
          formatter: (value) => {
            return value ? "Abierta" : "Cerrada";
          },
        },
        {
          key: "result",
          sortable: true,
          label: this.$t('tableReports.result').toUpperCase(),
          class: "text-center",
        },
        {
          key: "sisterRound",
          sortable: true,
          label: this.$t('tableReports.roundSister').toUpperCase(),
          class: "space-nowrap text-center",
        },
      ],
    };
  },
  validations: {
    typeform: {
      identifierNumber: {
        numeric,
        required,
      },
    },
  },
  methods: {
    async findRoundByIdentifierNumber() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log("ERROR");

      const { data } = await this.$http.get("round/find-round", {
        params: {
          identifierNumber: this.typeform.identifierNumber,
        },
      });

      if (data.ok) {
        this.tableData = data.rounds;
      }
    },
    async lookSisterRound(round) {
      this.round = round;
      this.roundIdentifierNumber = round.identifierNumber;
      try {
        const { data } = await this.$http.get("round/sisters", {
          params: {
            identifierNumber: round.identifierNumber,
          },
        });

        this.rounds = data.rounds;
      } catch (error) {
        console.log("Error obteniendo rondas hermanas", error);
      }

      this.$refs.sisterRound.modal = true;
    },
  },
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
  width: max-content;
}

.space-nowrap {
  white-space: nowrap !important;
}

.black {
  background: #000;
}

.red {
  background: #f10;
}

.green {
  background: #00a429;
}
</style>
